import { AppFeatures } from '../config/config';
import { ICrackDetectionPhoto } from '../config/interface';
import { ISearchTableRow } from '../pages/SearchPage';

export const parseDateTime = (dateTime: string, showTime?: boolean): React.ReactNode => {
    const dateObj = new Date(dateTime);
    const time = dateObj.toLocaleTimeString('default', {
        timeStyle: 'short',
    });
    const day = dateObj.toLocaleString('default', { day: '2-digit' });
    const monthYear = dateObj.toLocaleString('default', {
        month: 'short',
        year: '2-digit',
    });

    const result = showTime ? `${day} ${monthYear} ${time}` : `${day} ${monthYear}`;
    return result;
};

export const prettifyDuration = value => {
    const arr = value.split(' ');
    let secs = parseFloat((arr && arr.length > 1 ? arr[1] : arr[0]).replace('s', ''));
    if (arr && arr.length > 1) {
        arr[1] = secs > 0 ? Math.abs(Math.round(secs)) + 's' : '';
    } else {
        arr[0] = secs > 0 ? Math.abs(Math.round(secs)) + 's' : '';
    }
    return arr.join(' ');
};

export const convertISODateToDateTime = (dateISOString: string, hideTime?: boolean) => {
    const dateObj = new Date(dateISOString);

    const day = dateObj.toLocaleString('default', { day: '2-digit' });
    const monthYear = dateObj.toLocaleString('default', {
        month: 'short',
        year: '2-digit',
    });
    const time = dateObj.toLocaleTimeString('default', {
        timeStyle: 'short',
    });

    const result = hideTime ? `${day} ${monthYear}` : `${day} ${monthYear} ${time}`;
    return result;
};

export const convertDurationToMinuteSeconds = (duration: number) => {
    const minute = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);

    if (minute > 0) {
        return `${minute}m ${seconds}s`;
    } else {
        return `${seconds}s`;
    }
};

export const flattenTableData = (inputData: any[]) => {
    const resultArray: ISearchTableRow[] = [];

    inputData.forEach(enrollment => {
        let enrollmentObj = {} as ISearchTableRow;
        enrollment.session.forEach((sessionObj, idx) => {
            if (idx === 0) {
                enrollmentObj = {
                    ...sessionObj,
                    primaryMdn: enrollment.primaryMdn,
                    secondaryMdn: enrollment.secondaryMdn,
                    sessionCount: enrollment.session.length,
                    subRows: [],
                };
            } else {
                enrollmentObj['subRows']?.push({
                    ...sessionObj,
                    primaryMdn: enrollment.primaryMdn,
                    secondaryMdn: enrollment.secondaryMdn,
                    sessionCount: 0,
                });
            }
        });
        resultArray.push(enrollmentObj);
    });

    return resultArray;
};

export const mappedEnrollmentDetails = (
    data: any,
    primary: string,
    secondary: string
) => {
    data.map(d => {
        d.primary = primary;
        d.secondary = secondary;
        d.browser =
            !d.browserAgentFirst && !d.browserAgentSecond
                ? '-'
                : `${d.browserAgentFirst || ''} ${d.browserAgentSecond || ''}`;
        d.methodDuration =
            !d.method && !d.duration
                ? '-'
                : `${d.method || ''} ${prettifyDuration(d.duration || '')}`;
        d.partnerPlan =
            !d.partner && !d.program ? '-' : `${d.partner || ''} ${d.program || ''}`;
        d.enrolledDevice =
            !d.make && !d.model && !d.capacity && !d.color
                ? '-'
                : `${d.make || ''} ${d.model || ''} ${d.capacity || ''} ${d.color || ''}`;
    });

    return data;
};

export const getAiReviewPhotos = (crackDetectionDetails: ICrackDetectionPhoto[]) => {
    const aiReviewPhotoMapping = {};
    crackDetectionDetails.forEach(photo => {
        if (photo.source === 'ai' && photo.associatedFileId) {
            const fileId = photo.associatedFileId;
            aiReviewPhotoMapping[fileId] = photo;
        }
    });

    return aiReviewPhotoMapping;
};

export const parseErrorResponse = (error: any) => {
    return {
        message:
            error && error.response && error.response.data && error.response.data.error
                ? error.response.data.error
                : 'An unknown error occurred',
        statusCode: error && error.response && error.response.status,
    };
};

export const isFeatureEnabled = (featureKey: AppFeatures) => {
    const disabled = process.env.REACT_APP_DISABLED_FEATURES;
    return disabled && disabled.indexOf(featureKey) > -1 ? false : true;
};

export const formatGtmTimestamp = (dateObj: Date) => {
    const time = dateObj.toLocaleTimeString('default', {
        timeStyle: 'medium',
    });
    const day = dateObj.toLocaleString('default', { day: '2-digit' });
    const monthYear = dateObj.toLocaleString('default', {
        month: '2-digit',
        year: '2-digit',
    });

    return `${day}/${monthYear} ${time}`;
};
