import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    Flex,
    Grid,
    GridItem,
    Spacer,
    Spinner,
    Text,
} from '@chakra-ui/react';
import VerticalCenter from '../layouts/VerticalCenter';
import { AppFeatures, SCREEN_STATE, STATUS } from '../../config/config';
import { account } from '../../entities/session';
import StatusChip from '../Table/StatusChip';
import WorkflowActions from './WorkflowActions';
import CircleCheckSvg from '../../assets/CircleCheckSvg';
import ConfirmDialog from '../ConfirmDialog';
import WorkflowForm from './WorkflowForm';
import RejectScreenSvg from '../../assets/RejectScreenSvg';
import RedoScreenSvg from '../../assets/RedoScreenSvg';
import EscalateScreenSvg from '../../assets/EscalateScreenSvg';
import DetailTextWithIcon from './fields/DetailTextWithIcon';
import { IEnrollmentDetails } from '../../config/interface';
import { isFeatureEnabled } from '../../lib/helpers';
import ApproveScreenSvg from '../../assets/ApproveScreenSvg';
import Dropzone from 'react-dropzone';
import { useMutation } from '@tanstack/react-query';
import { uploadFile } from '../../services/apiService';

const ListItem = ({
    recordId,
    status,
    item,
    checked,
    onChange,
    disabled,
    uploading,
    onDrop,
    onCancel,
}) => {
    const preventDefault = event => {
        event.preventDefault();
    };
    return (
        <Box>
            {status === STATUS.PENDING || status === STATUS.ASSIGNED ? (
                <>
                    <Checkbox
                        color={'asuGray.200'}
                        mb={'10px'}
                        mr={'8px'}
                        colorScheme={'asuPurple'}
                        lineHeight={'40px'}
                        size={'lg'}
                        inputProps={{
                            width: '24px',
                            height: '24px',
                        }}
                        onChange={e => {
                            if (onChange) onChange(item.key, e);
                        }}
                        disabled={disabled}
                    >
                        <Text color="black" fontSize={'18px'} fontWeight={400}>
                            {item.label}
                        </Text>
                    </Checkbox>
                    {item.key === 'spf' &&
                        isFeatureEnabled(AppFeatures.SPF_UPLOAD) &&
                        !uploading && (
                            <Dropzone
                                onDrop={onDrop}
                                onFileDialogCancel={onCancel}
                                maxFiles={1}
                                accept={{ 'application/pdf': ['.pdf'] }}
                                disabled={disabled}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <Box
                                        {...getRootProps({
                                            onAbort: preventDefault,
                                            onDrop: preventDefault,
                                            onSelect: preventDefault,
                                        })}
                                        borderWidth={'1px'}
                                        borderStyle={'solid'}
                                        borderColor={
                                            !disabled ? 'asuPurple.500' : 'asuGray.100'
                                        }
                                        borderRadius={'8px'}
                                        textAlign={'center'}
                                        width={'102px'}
                                        cursor={!disabled ? 'pointer' : 'unset'}
                                        float={'right'}
                                        p={'8px 0'}
                                    >
                                        <input type={'file'} {...getInputProps()} />
                                        <Text
                                            color={
                                                !disabled
                                                    ? 'asuPurple.500'
                                                    : 'asuGray.100'
                                            }
                                            fontSize={'14px'}
                                        >
                                            Attach
                                        </Text>
                                    </Box>
                                )}
                            </Dropzone>
                        )}
                    {item.key === 'spf' &&
                        isFeatureEnabled(AppFeatures.SPF_UPLOAD) &&
                        uploading && (
                            <Spinner
                                variant={'compoentLoader'}
                                id={`spinner-spfupload-${item.key}-${recordId}`}
                            />
                        )}
                </>
            ) : (
                <>
                    {checked && (
                        <DetailTextWithIcon
                            icon={<CircleCheckSvg />}
                            value={item.label}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

interface Props {
    recordId: string;
    details: IEnrollmentDetails;
    reviewer?: string;
    reviewReason?: string;
    reviewNotes?: string;
    reviewCompleted?: string[];
    reviewerChecklist: any;
    reviewerReasons: any;
    hasSpf: boolean;
    isUpdating: boolean;
    isAssigning: boolean;
    isUnAssigning: boolean;
    onSpfUploadInProgress?: Function;
    onSpfUpload?: Function;
    onSpfDrop?: Function;
    onSpfCancel?: Function;
    onSubmit?: Function;
    onAssignToMe?: Function;
    onUnassigned?: Function;
}

const ReviewerChecklist: React.FC<Props> = ({
    recordId,
    details,
    reviewerChecklist,
    reviewCompleted,
    hasSpf = false,
    isUpdating = false,
    isAssigning = false,
    isUnAssigning = false,
    reviewerReasons,
    onSpfUpload = () => {},
    onSpfUploadInProgress = () => {},
    onSpfDrop = () => {},
    onSpfCancel = () => {},
    onSubmit = () => {},
    onAssignToMe = () => {},
    onUnassigned = () => {},
}) => {
    const { reviewer, reviewNotes, reviewReason } = details;
    let reviewStatus = details?.reviewStatus || STATUS.PENDING;
    reviewStatus = reviewStatus?.toUpperCase();

    const [screenState, setScreenState] = useState<SCREEN_STATE>(SCREEN_STATE.DEFAULT);
    const [toggleConfirmApprove, setToggleConfirmApprove] = useState<boolean>(false);
    const [toggleConfirmUnassign, setToggleConfirmUnassign] = useState<boolean>(false);
    const [checklist, setChecklist] = useState<any>([]);
    const [checklistUpdate, setChecklistUpdate] = useState<any>(null);
    const [reasons, setReasons] = useState<any>(null);
    const [status, setStatus] = useState<any>();
    const [isApprovalValidated, setApprovalValidated] = useState<boolean>(false);

    const accountInfo: any = account.use();

    const { mutate: upload, isLoading: isSpfLoading } = useMutation(uploadFile, {
        onSuccess: ({ success }) => {
            onSpfUploadInProgress({ recordId, inProgress: false, status: 'success' });
            if (success) {
                onSpfUpload(recordId);
            }
        },
        onError: () => {
            onSpfUploadInProgress({ recordId, inProgress: false, status: 'error' });
        },
    });

    const onDropSpfDoc = acceptedFiles => {
        const file = acceptedFiles && acceptedFiles.length > 0 ? acceptedFiles[0] : null;

        if (file && recordId) {
            onSpfUploadInProgress({ recordId, inProgress: true, status: 'inprogress' });
            onSpfDrop(file);
            upload({ recordId, uploadType: 'doc', subType: 'spf', file });
        }
    };

    const onDropCancelSpfDoc = () => {
        onSpfCancel(recordId);
    };

    const onChecklistChange = (checkId, event) => {
        let list = checklistUpdate;
        checklist.map(item => {
            if (checkId === item.key) {
                list[checkId] = event.target.checked;
            }
        });
        setChecklistUpdate(list);
        validateChecklist();
    };

    /**
     * When user clicks Approve, all items should be checked
     */
    const validateChecklist = () => {
        let allChecked = true;
        const list = checklistUpdate;
        checklist.map(item => {
            if (item && !item.optional) {
                allChecked = allChecked && list[item.key];
            }
        });

        if (allChecked) {
            setApprovalValidated(true);
        } else {
            setApprovalValidated(false);
        }
    };

    /**
     * When user trying to change workflow of the session
     */
    const onWorkflowChange = useCallback(
        screen => {
            if (screen !== screenState) {
                setScreenState(screen.toUpperCase());
            }
        },
        [screenState]
    );

    /**
     * When user click submit on confirm
     */
    const onSubmitWorkflow = useCallback(
        (screen, data) => {
            switch (screen) {
                case SCREEN_STATE.REJECTED:
                    console.info('SESSION REJECTED', data);
                    break;
                case SCREEN_STATE.REDO:
                    console.info('SESSION REDO', data);
                    break;
                case SCREEN_STATE.APPROVED:
                    console.info('SESSION APPROVED', data, checklistUpdate);

                    break;
                case SCREEN_STATE.ESCALATED:
                    console.info('SESSION ESCALATE', data);
                    break;
                default:
                    break;
            }
            setScreenState(SCREEN_STATE.DEFAULT);
            onSubmit({
                id: recordId,
                workflow: screen,
                checklist: checklistUpdate,
                reason: data?.reason || '',
                notes: data?.notes || '',
            });
        },
        [recordId, checklistUpdate, onSubmit]
    );

    useEffect(() => {
        if (reviewerChecklist && checklist.length === 0) {
            let list = {};
            reviewerChecklist.map(item => {
                list[item.key] = false;
            });

            setChecklist(reviewerChecklist);
            setChecklistUpdate(list);
        }

        if (reviewerReasons && !reasons) {
            setReasons(reviewerReasons);
        }
    }, [reviewerChecklist, checklist, reasons, reviewerReasons]);

    useEffect(() => {
        if (reviewStatus !== status) {
            setStatus(reviewStatus);
        }
    }, [status, reviewStatus]);

    useEffect(() => {
        if (
            screenState === SCREEN_STATE.APPROVED &&
            !isFeatureEnabled(AppFeatures.APPROVAL_NOTES)
        ) {
            setToggleConfirmApprove(true);
        }
    }, [screenState]);

    const isDisabled = (requirement, spf, imei) => {
        let disabled = false;

        if (requirement === 'file') {
            if (!spf) {
                disabled = true;
            }
        }

        if (!reviewer) {
            disabled = true;
        }

        if (reviewer !== accountInfo?.name) {
            disabled = true;
        }

        if (isSpfLoading) {
            disabled = true;
        }

        if (!imei) {
            disabled = true;
        }

        return disabled;
    };

    console.info('REVIEW STATUS', reviewStatus);

    return (
        <Box position={'relative'}>
            {toggleConfirmApprove && (
                <ConfirmDialog
                    open={toggleConfirmApprove}
                    content={
                        <>
                            Are you sure you want to&nbsp;<b>Approve</b>&nbsp;this session
                        </>
                    }
                    onYes={() => {
                        onSubmitWorkflow(SCREEN_STATE.APPROVED, {});
                        setToggleConfirmApprove(false);
                        onWorkflowChange(SCREEN_STATE.DEFAULT);
                    }}
                    onCancel={() => {
                        setToggleConfirmApprove(false);
                        onWorkflowChange(SCREEN_STATE.DEFAULT);
                    }}
                    yesLabel={'Submit'}
                    noLabel={'Cancel'}
                />
            )}
            {toggleConfirmUnassign && (
                <ConfirmDialog
                    open={toggleConfirmUnassign}
                    content={
                        <>
                            Are you sure you want to&nbsp;<b>Unassign</b>&nbsp;this
                            session
                        </>
                    }
                    onYes={() => {
                        setToggleConfirmUnassign(false);
                        onUnassigned(recordId);
                    }}
                    onCancel={() => {
                        setToggleConfirmUnassign(false);
                    }}
                    yesLabel={'Yes'}
                    noLabel={'No'}
                />
            )}
            {(isUpdating || isAssigning || isUnAssigning) && (
                <Box
                    className="reviewer-checklist-loader"
                    position={'absolute'}
                    width={'100%'}
                    height={'100%'}
                    zIndex={999}
                    backgroundColor={'white'}
                    opacity={'0.8'}
                >
                    <VerticalCenter
                        id="reviewer-checklist-loader"
                        innerProps={{ w: 'auto', m: '0', borderRadius: '8px' }}
                        h={'100%'}
                    >
                        <Spinner variant="checkListLoader" />
                    </VerticalCenter>
                </Box>
            )}
            {screenState === SCREEN_STATE.DEFAULT ||
            (screenState === SCREEN_STATE.APPROVED &&
                !isFeatureEnabled(AppFeatures.APPROVAL_NOTES)) ||
            screenState === SCREEN_STATE.ASSIGNED ? (
                <Box id={`reviewer-checklist-${recordId}`}>
                    {checklist.length === 0 && (
                        <VerticalCenter
                            innerProps={{ w: 'auto', m: '0', borderRadius: '8px' }}
                            h={'370px'}
                        >
                            <Spinner variant={'componentLoader'} />
                        </VerticalCenter>
                    )}
                    {checklist.length === 0 && (
                        <VerticalCenter
                            innerProps={{ w: 'auto', m: '0', borderRadius: '8px' }}
                            h={'370px'}
                        >
                            <Text fontSize={'24px'}>
                                No checklist found for this carrier
                            </Text>
                        </VerticalCenter>
                    )}
                    {checklist.length > 0 && (
                        <>
                            {status !== STATUS.PENDING && (
                                <Grid
                                    templateAreas={`"reviewer status"
                                        "name status"`}
                                    gridTemplateRows={'auto 1fr'}
                                    gridTemplateColumns={'300px 1fr'}
                                >
                                    <GridItem area={'reviewer'}>
                                        <Text
                                            fontSize={'18px'}
                                            fontWeight={400}
                                            color={'asuGray.200'}
                                        >
                                            Reviewer
                                        </Text>
                                    </GridItem>
                                    <GridItem area={'status'}>
                                        <Flex direction={'row-reverse'}>
                                            <Box>
                                                <StatusChip
                                                    status={status}
                                                    fontSize={'12px'}
                                                />
                                            </Box>
                                            <Box pt={'4px'} pr={'4px'}>
                                                <Text
                                                    fontSize={'18px'}
                                                    fontWeight={400}
                                                    color={'asuGray.200'}
                                                >
                                                    Review Status
                                                </Text>
                                            </Box>
                                        </Flex>
                                    </GridItem>
                                    <GridItem area={'name'} paddingRight={'10px'}>
                                        <Text
                                            fontSize={'18px'}
                                            fontWeight={400}
                                            color={'black'}
                                        >
                                            {status === STATUS.PENDING && (
                                                <>{accountInfo?.name}</>
                                            )}
                                            {status === STATUS.ASSIGNED && (
                                                <>
                                                    {reviewer}&nbsp;
                                                    {reviewer === accountInfo?.name && (
                                                        <Button
                                                            variant="solid"
                                                            p={'0px 6px'}
                                                            minH={'unset'}
                                                            fontSize={'12px'}
                                                            ml={'10px'}
                                                            onClick={() => {
                                                                setToggleConfirmUnassign(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            Unassigned
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                            {status !== STATUS.PENDING &&
                                                status !== STATUS.ASSIGNED && (
                                                    <>{reviewer}</>
                                                )}
                                        </Text>
                                    </GridItem>
                                </Grid>
                            )}

                            <Flex direction={'row'}>
                                <Box>
                                    <Box>
                                        <Flex direction={'row'} alignItems={'center'}>
                                            <Box marginRight={'10px'}>
                                                <Text
                                                    fontSize={'24px'}
                                                    fontWeight={700}
                                                    color="black"
                                                    my={'20px'}
                                                >
                                                    Reviewer Checklist
                                                </Text>
                                            </Box>
                                            {status === STATUS.PENDING && (
                                                <Flex
                                                    direction={'row'}
                                                    gap={2}
                                                    alignItems={'center'}
                                                >
                                                    <Box>
                                                        <StatusChip
                                                            status={status}
                                                            fontSize={'12px'}
                                                        />
                                                    </Box>
                                                    {!reviewer && (
                                                        <Box>
                                                            <Button
                                                                variant={'solid'}
                                                                p={'4px 8px'}
                                                                m={0}
                                                                fontSize={'12px'}
                                                                h={'32px'}
                                                                minH={'unset'}
                                                                borderRadius={'4px'}
                                                                onClick={() => {
                                                                    onAssignToMe(
                                                                        recordId
                                                                    );
                                                                }}
                                                            >
                                                                Assign To Me
                                                            </Button>
                                                        </Box>
                                                    )}
                                                </Flex>
                                            )}
                                        </Flex>
                                    </Box>
                                    <Flex direction={'row'}>
                                        <Flex direction={'column'}>
                                            {checklist.map((item, index) => {
                                                return (
                                                    index < 5 && (
                                                        <ListItem
                                                            recordId={recordId}
                                                            status={status}
                                                            key={`checklist-${item.key}-${index}`}
                                                            item={item}
                                                            onChange={onChecklistChange}
                                                            onDrop={onDropSpfDoc}
                                                            onCancel={onDropCancelSpfDoc}
                                                            disabled={isDisabled(
                                                                item.requirement,
                                                                hasSpf,
                                                                details.imei
                                                            )}
                                                            uploading={isSpfLoading}
                                                            checked={
                                                                reviewCompleted &&
                                                                reviewCompleted &&
                                                                reviewCompleted[item.key]
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    )
                                                );
                                            })}
                                        </Flex>
                                        {checklist.length > 5 && (
                                            <Flex direction={'column'}>
                                                {checklist.map((item, index) => {
                                                    return (
                                                        index > 4 && (
                                                            <ListItem
                                                                recordId={recordId}
                                                                status={status}
                                                                key={`checklist-${item.key}-${index}`}
                                                                item={item}
                                                                onDrop={onDropSpfDoc}
                                                                onCancel={
                                                                    onDropCancelSpfDoc
                                                                }
                                                                onChange={
                                                                    onChecklistChange
                                                                }
                                                                disabled={isDisabled(
                                                                    item.requirement,
                                                                    hasSpf,
                                                                    details.imei
                                                                )}
                                                                uploading={isSpfLoading}
                                                                checked={
                                                                    reviewCompleted &&
                                                                    reviewCompleted &&
                                                                    reviewCompleted[
                                                                        item.key
                                                                    ]
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        )
                                                    );
                                                })}
                                            </Flex>
                                        )}
                                    </Flex>
                                </Box>
                                <Spacer />
                                <Box>
                                    {(status === STATUS.PENDING ||
                                        status === STATUS.ASSIGNED) &&
                                        reviewer && (
                                            <WorkflowActions
                                                onWorkflowChange={onWorkflowChange}
                                                details={details}
                                                isApprovalValidated={isApprovalValidated}
                                            />
                                        )}
                                </Box>
                            </Flex>

                            {status !== STATUS.PENDING && status !== STATUS.ASSIGNED && (
                                <Flex direction={'column'} mt={'20px'}>
                                    <Box>
                                        <Divider mt={'10px'} />
                                        <Text
                                            fontSize={'24px'}
                                            fontWeight={700}
                                            color="black"
                                            my={'10px'}
                                            textTransform={'capitalize'}
                                        >
                                            {status?.replaceAll('_', ' ').toLowerCase()}{' '}
                                            Reason
                                        </Text>
                                    </Box>
                                    <Box id={`reviewReason-${recordId}`}>
                                        {typeof reviewReason === 'string' && (
                                            <Text
                                                fontSize={'16px'}
                                                color="black"
                                                textTransform={'capitalize'}
                                            >
                                                {reviewReason || '-'}
                                            </Text>
                                        )}
                                        {typeof reviewReason === 'object' && (
                                            <Flex direction={'column'}>
                                                {reviewReason.map((item, index) => {
                                                    return (
                                                        <Text
                                                            key={`review-reason-${index}`}
                                                            fontSize={'16px'}
                                                            color="black"
                                                            textTransform={'capitalize'}
                                                        >
                                                            {item}
                                                        </Text>
                                                    );
                                                })}
                                            </Flex>
                                        )}
                                    </Box>
                                    <Box>
                                        <Divider mt={'10px'} />
                                        <Text
                                            fontSize={'24px'}
                                            fontWeight={700}
                                            color="black"
                                            my={'10px'}
                                            textTransform={'capitalize'}
                                        >
                                            Notes
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Text
                                            fontSize={'16px'}
                                            color="black"
                                            textTransform={'capitalize'}
                                        >
                                            {reviewNotes || '-'}
                                        </Text>
                                    </Box>
                                </Flex>
                            )}
                        </>
                    )}
                </Box>
            ) : (
                <></>
            )}

            {screenState === SCREEN_STATE.APPROVED &&
                isFeatureEnabled(AppFeatures.APPROVAL_NOTES) && (
                    <WorkflowForm
                        recordId={recordId}
                        screenState={SCREEN_STATE.APPROVED}
                        reasons={reasons.approve || []}
                        screenLabels={{
                            screenTitle: 'Approve',
                            confirmMessage: (
                                <>
                                    Are you sure you want to&nbsp;<b>Approve</b>&nbsp;this
                                    session
                                </>
                            ),
                            goBackBtn: 'Go Back',
                            submitBtn: 'Submit',
                            reasonLabel: '',
                            noteLabel: 'Notes',
                            reasonPlaceholder: '',
                            notePlaceholder: 'Type notes here',
                        }}
                        onSubmit={onSubmitWorkflow}
                        onWorkflowChange={onWorkflowChange}
                        iconSvg={<ApproveScreenSvg />}
                    />
                )}

            {screenState === SCREEN_STATE.REJECTED && reasons?.reject && (
                <WorkflowForm
                    recordId={recordId}
                    screenState={SCREEN_STATE.REJECTED}
                    reasons={reasons.reject || []}
                    screenLabels={{
                        screenTitle: 'Reject',
                        confirmMessage: (
                            <>
                                Are you sure you want to&nbsp;<b>Reject</b>&nbsp;this
                                session
                            </>
                        ),
                        goBackBtn: 'Go Back',
                        submitBtn: 'Submit',
                        reasonLabel: 'Reject Reason',
                        noteLabel: 'Notes',
                        reasonPlaceholder: 'Enter reject reason',
                        notePlaceholder: 'Type notes here',
                    }}
                    onSubmit={onSubmitWorkflow}
                    onWorkflowChange={onWorkflowChange}
                    iconSvg={<RejectScreenSvg />}
                />
            )}

            {screenState === SCREEN_STATE.REDO && reasons?.redo && (
                <WorkflowForm
                    recordId={recordId}
                    screenState={SCREEN_STATE.REDO}
                    reasons={reasons.redo || []}
                    screenLabels={{
                        screenTitle: 'Redo',
                        confirmMessage: (
                            <>
                                Are you sure you want to&nbsp;<b>Redo</b>&nbsp;this
                                session
                            </>
                        ),
                        goBackBtn: 'Go Back',
                        submitBtn: 'Submit',
                        reasonLabel: 'Redo Reason',
                        noteLabel: 'Notes',
                        reasonPlaceholder: 'Enter redo reason',
                        notePlaceholder: 'Type notes here',
                    }}
                    onSubmit={onSubmitWorkflow}
                    onWorkflowChange={onWorkflowChange}
                    iconSvg={<RedoScreenSvg />}
                />
            )}

            {screenState === SCREEN_STATE.ESCALATED && reasons?.escalate && (
                <WorkflowForm
                    recordId={recordId}
                    screenState={SCREEN_STATE.ESCALATED}
                    reasons={reasons.escalate || []}
                    screenLabels={{
                        screenTitle: 'Escalate',
                        confirmMessage: (
                            <>
                                Are you sure you want to&nbsp;<b>Escalate</b>&nbsp;this
                                session
                            </>
                        ),
                        goBackBtn: 'Go Back',
                        submitBtn: 'Submit',
                        reasonLabel: 'Escalate Reason',
                        noteLabel: 'Notes',
                        reasonPlaceholder: 'Enter escalate reason',
                        notePlaceholder: 'Type notes here',
                    }}
                    onSubmit={onSubmitWorkflow}
                    onWorkflowChange={onWorkflowChange}
                    iconSvg={<EscalateScreenSvg />}
                />
            )}
        </Box>
    );
};

export default ReviewerChecklist;
