import { AuthConfigs } from './interface';

export const AppConfig = {
    name: 'SmartScan Console',
    defaultRegion: process.env.REACT_APP_APP_REGION,
    defaultCarrier: 'singtel',
    regions: [
        { key: 'sea', label: 'SEA' },
        { key: 'nuvali', label: 'Nuvali' },
    ],
    languages: [
        { key: 'en-US', label: 'English' },
        { key: 'ko', label: 'Korean' },
        { key: 'zh-tw', label: 'Chinese (Traditional)' },
    ],
};

export const AppScreen = {
    minWidth: '1920px',
    headerMenuHeight: '60px',
    reviewPage: {
        detailsMinWidth: '1200px',
        checklistMinWidth: '600px',
        mediaWidth: '360px',
        mediaHeight: '640px',
        canvasWidth: 360,
        canvasHeight: 640,
    },
};

export const AppRoutes = {
    DASHBOARD: '/dashboard',
    AUTH: '/auth',
    SEARCH: '/search',
    INDEX: '/',
    LOGIN: '/login',
    LOGOUT: '/logout',
    ENROLLMENT: '/enrollment',
};

export const Api = {
    USERINFO: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_USER_INFO}`,
    ENROLLMENTDETAILS: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_ENROLLMENT_DETAILS}`,
    UPDATE_ENROLLMENTDETAILS: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_UPDATE_ENROLLMENT_DETAILS}`,
    SEARCH: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_SEARCH}`,
    EXPORT_SEARCH: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_EXPORT_SEARCH}`,
    FILTERS: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_FILTERS}`,
    ENROLLMENTPHOTOS: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_ENROLLMENT_PHOTOS}`,
    ENROLLMENTVIDEOS: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_ENROLLMENT_VIDEOS}`,
    ENROLLMENTFILES: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_ENROLLMENT_FILES}`,
    REVIEWERCHECKLIST: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_REVIEWER_CHECKLIST}`,
    REVIEWERNOTES:
        'https://dummyjson.com/' + `${process.env.REACT_APP_API_REVIEWER_NOTES}`,
    WORKFLOWREASONS:
        'https://dummyjson.com/' + `${process.env.REACT_APP_API_WORKFLOW_REASONS}`,
    TWILIOTOKEN: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_FETCH_TWILIO_TOKEN}`,
    UPDATE_WORKERSTATUS: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_UPDATE_WORKER_STATUS}`,
    FETCH_FMIP_STATUS: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_FETCH_FMIP_STATUS}`,
    VERIFY_IMEI: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_VERIFY_IMEI}`,
    FETCH_PRESIGNED_URL: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_FETCH_PRESIGNED_URL}`,
    ASSIGN_TASK_URL: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_ASSIGN_TASK}`,
    UNASSIGN_TASK_URL: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_UNASSIGN_TASK}`,
    CRACK_DETECTION_DETAILS: `${process.env.REACT_APP_BASE_API}/${process.env.REACT_APP_API_CRACK_DETECTION_DETAILS}`,
};

export const getAmplifyConfigs = (domainApp: string): AuthConfigs => {
    const config = {
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOLID || '',
        region: process.env.REACT_APP_COGNITO_REGION || '',
        userPoolId: process.env.REACT_APP_COGNITO_USERPOOLID || '',
        userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOLWEBCLIENTID || '',
        identityPoolRegion: process.env.REACT_APP_COGNITO_REGION || '',
        oauth: {
            domain: process.env.REACT_APP_COGNITO_DOMAIN || '',
            scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: `${domainApp}/${process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN}`,
            redirectSignOut: `${domainApp}`,
            responseType: process.env.REACT_APP_COGNITO_RESPONSE_TYPE || '',
            idp: [
                {
                    title: process.env.REACT_APP_COGNITO_IDENTITY_PROVIDER_TITLE || '',
                    color: 'blue',
                    identityProvider:
                        process.env.REACT_APP_COGNITO_IDENTITY_PROVIDER_NAME || '',
                },
            ],
            identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOLID || '',
            region: process.env.REACT_APP_COGNITO_REGION || '',
            userPoolId: process.env.REACT_APP_COGNITO_USERPOOLID || '',
            userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOLWEBCLIENTID || '',
            identityPoolRegion: process.env.REACT_APP_COGNITO_REGION || '',
        },
    };

    return config;
};

export enum STATUS {
    COMPLETE = 'COMPLETE',
    APPROVED = 'APPROVED',
    CLOSED = 'CLOSED',
    INCOMPLETE = 'INCOMPLETE',
    REJECTED = 'REJECTED',
    AUTO_CLOSED = 'AUTO_CLOSED',
    PENDING = 'PENDING',
    ASSIGNED = 'ASSIGNED',
    REDO = 'REDO',
    ESCALATED = 'ESCALATED',
    UNDEFINED = 'UNDEFINED',
}

export const enum SCREEN_STATE {
    DEFAULT = '',
    ASSIGNED = 'ASSIGNED',
    REJECTED = 'REJECTED',
    APPROVED = 'APPROVED',
    REDO = 'REDO',
    ESCALATED = 'ESCALATED',
}

export enum TaskStatus {
    Pending = 'pending',
    Reserved = 'reserved',
    Assigned = 'assigned',
    Wrapping = 'wrapping',
    Canceled = 'canceled',
    Completed = 'completed',
}

export enum NotificationStatus {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
}

export enum UserRoles {
    REVIEWER = 'reviewer',
    MANAGER = 'manager',
}

export enum AppFeatures {
    REDO = 'workflow_redo',
    ESCALATED = 'workflow_escalated',
    APPROVED = 'workflow_approved',
    REJECTED = 'workflow_rejected',
    FLOATING_NOTES = 'floating_notes',
    WORKER_STATUS = 'worker_status',
    SPF_UPLOAD = 'spf_uploader',
    REVIEW_DOCUMENTS = 'review_documents',
    EXPORT_SEARCH = 'export_search',
    APPROVAL_NOTES = 'approval_notes',
    VIDEO_SCREENSHOT = 'video_screenshot',
    IMEI_UPDATE_VALIDATION = 'imei_update_validation',
    MULTIPLE_REVIEWER_REASON = 'multiple_reviewer_reason',
    TWILIO = 'twilio',
}
