import axios from 'axios';
import { Api, AppConfig } from '../config/config';
import { IEnrollmentDetails } from '../config/interface';
import { getApiToken } from '../entities/session';

import { mappedEnrollmentDetails } from '../lib/helpers';
import { getReviewNotes } from '../mocks/getReviewNotes';

const execute = async (method, url, params?, isSecured = true) => {
    let options: any = {};
    const apiToken = getApiToken()?.replaceAll('"', '');

    if (isSecured) {
        options.headers = {
            'x-smartscan-console-authtoken': apiToken,
        };
    }

    switch (method.toUpperCase()) {
        case 'GET':
            return await axios.get(url, options);
            break;
        case 'POST':
            return await axios.post(url, params?.data, options);
            break;
        case 'PUT':
            return await axios.put(url, params?.data, options);
            break;
        case 'DELETE':
            return await axios.delete(url, options);
            break;
        default:
            return await axios.get(url, options);
            break;
    }
};

/**
 * Get all user information
 */
export const fetchUserInfo = async ({
    email,
    region = AppConfig?.defaultRegion || '',
}: {
    email: string;
    region: string;
}) => {
    if (email && region) {
        const { data } = await execute(
            'GET',
            Api.USERINFO.replaceAll('USER_EMAIL', email).replaceAll(
                'USER_REGION',
                region
            ),
            {},
            false
        );
        return data;
    } else {
        return null;
    }
};

/**
 * Get enrollment details using primary and secondary mdn
 */
export const fetchEnrollmentDetails = async (primary, secondary) => {
    const { data } = await execute(
        'GET',
        Api.ENROLLMENTDETAILS.replace('PRIMARY', primary).replaceAll(
            'SECONDARY',
            secondary
        ),
        {}
    );
    return mappedEnrollmentDetails(data, primary, secondary);
};

/**
 * Get all enrollment records based on filters/limit
 */
export const fetchEnrollmentRecords = async (limit, pageToken, filterFormData) => {
    const params = {
        data: filterFormData,
    };

    let queryString = `${Api.SEARCH}`;

    if (limit && !pageToken) {
        queryString += `?limit=${limit}`;
    }
    if (limit && pageToken) {
        queryString += `?limit=${limit}&pageToken=${pageToken}`;
    }

    if (Object.keys(filterFormData).length === 0) {
        const { data } = await execute('GET', queryString, {});
        return data;
    } else {
        const { data } = await execute('POST', queryString, params);
        return data;
    }
};

/**
 * Get all search filters
 */
export const fetchEnrollmentFilters = async (filterType: string, region?: string) => {
    if (filterType === 'reviewerName') {
        const { data } = await execute(
            'GET',
            `${Api.FILTERS}/${filterType}?region=${region}`,
            {}
        );
        return data;
    } else {
        const { data } = await execute('GET', `${Api.FILTERS}/${filterType}`, {});
        return data;
    }
};

/**
 * Export search table results
 */
export const exportSearchResultsToCsv = async filterFormData => {
    const params = {
        data: filterFormData,
    };
    const { data } = await execute('POST', Api.EXPORT_SEARCH, params);
    return data;
};

/**
 * Get videos specific to record
 */
export const fetchEnrollmentVideos = async recordId => {
    if (recordId) {
        const { data } = await execute(
            'GET',
            Api.ENROLLMENTVIDEOS.replace('RECORD_ID', recordId),
            {}
        );
        return data;
    } else {
        return [];
    }
};

/**
 * Get all photos specific to record
 */
export const fetchEnrollmentPhotos = async recordId => {
    if (recordId) {
        const { data } = await execute(
            'GET',
            Api.ENROLLMENTPHOTOS.replace('RECORD_ID', recordId),
            {}
        );
        return data;
    } else {
        return [];
    }
};

/**
 * Get crack detection details, AI photo for specific record
 */
export const fetchCrackDetectionData = async recordId => {
    if (recordId) {
        const { data } = await execute(
            'GET',
            Api.CRACK_DETECTION_DETAILS.replace('RECORD_ID', recordId),
            {}
        );
        return data;
    } else {
        return [];
    }
};

/**
 * Get all files specific to record and file type
 */
export const fetchEnrollmentFiles = async ({ recordId, fileType }) => {
    if (recordId) {
        const { data } = await execute(
            'GET',
            Api.ENROLLMENTFILES.replaceAll('RECORD_ID', recordId).replaceAll(
                'FILE_TYPE',
                fileType
            ),
            {}
        );
        return data;
    } else {
        return [];
    }
};

/**
 * Get reviewer checklist based on carrier
 */
export const fetchReviewerChecklist = async (carrier = AppConfig.defaultCarrier) => {
    if (carrier) {
        const { data } = await execute(
            'GET',
            Api.REVIEWERCHECKLIST.replaceAll('CARRIER', carrier),
            {}
        );

        return data;
    } else {
        return {};
    }
};

export const fetchReviewerNotes = async id => {
    if (id) {
        // const { data } = await execute('GET', Api.REVIEWERNOTES);

        return getReviewNotes();
    } else {
        return [];
    }
};

/**
 * Fetch workflow reasons
 */
export const fetchWorkflowReasons = async id => {
    if (id) {
        const { data } = await execute('GET', Api.WORKFLOWREASONS, {});

        return data;
    } else {
        return [];
    }
};

/**
 * Update fields in enrollment records
 */
export const updateEnrollmentDetails = async ({
    recordId,
    details,
}: {
    recordId: string;
    details: IEnrollmentDetails;
}) => {
    const { data } = await execute(
        'PUT',
        Api.UPDATE_ENROLLMENTDETAILS.replace('RECORD_ID', recordId),
        { data: details }
    );
    return { recordId, details: data };
};

/**
 * Fetch workspace and worker tokens
 */
export const fetchTwilioToken = async ({
    region = AppConfig?.defaultRegion || '',
    workerSid,
}: {
    region: string;
    workerSid: string;
}) => {
    const { data } = await execute(
        'GET',
        Api.TWILIOTOKEN.replaceAll('REGION', region).replaceAll('WKSID', workerSid),
        {},
        false
    );
    return data;
};

/**
 * Update worker status - AVAILABLE, UNAVAILABLE and OFFLINE
 */
export const updateWorkerStatus = async ({
    region = AppConfig?.defaultRegion || '',
    workerSid,
    status,
}: {
    region: string;
    workerSid: string;
    status: string;
}) => {
    const { data } = await execute(
        'PUT',
        Api.UPDATE_WORKERSTATUS.replaceAll('REGION', region).replaceAll(
            'WKSID',
            workerSid
        ),
        { data: { status } }
    );
    return data;
};

/**
 * Fetch the status of fmip
 */
export const fetchFmipStatus = async ({ imei }: { imei: string }) => {
    if (imei) {
        const { data } = await execute(
            'GET',
            Api.FETCH_FMIP_STATUS.replaceAll('IMEI', imei),
            {}
        );

        return data;
    } else {
        return {};
    }
};

/**
 * Verification of IMEI
 */
export const verifyImei = async ({ recordId }: { recordId: string }) => {
    if (recordId) {
        const { data } = await execute(
            'GET',
            Api.VERIFY_IMEI.replaceAll('RECORD_ID', recordId),
            {}
        );

        return data;
    } else {
        return {};
    }
};
/**
 * Upload a file to certain record id
 */
export const uploadFile = async ({
    file,
    recordId,
    uploadType,
    subType,
    associatedFileId,
}: {
    file: any;
    recordId: string;
    uploadType: string;
    subType: string;
    associatedFileId?: string;
}) => {
    if (recordId && uploadType) {
        const { data } = await execute(
            'POST',
            Api.FETCH_PRESIGNED_URL.replaceAll('RECORD_ID', recordId).replaceAll(
                'UPLOAD_TYPE',
                uploadType
            ),
            associatedFileId
                ? { data: { subType, associatedFileId } }
                : { data: { subType } }
        );

        const presignedUrl = data && data?.url;

        await execute('PUT', presignedUrl, { data: file });

        return {
            success: true,
        };
    } else {
        return {
            error: 'No record Id and upload type found.',
        };
    }
};

/**
 * Assign a specific task to worker (assign to me)
 */
export const assignTask = async ({
    recordId,
    reviewerWorkerSid,
    reviewer,
}: {
    recordId: string;
    reviewerWorkerSid: string;
    reviewer: string;
}) => {
    const { data } = await execute(
        'PUT',
        Api.ASSIGN_TASK_URL.replace('RECORD_ID', recordId),
        { data: { reviewer, reviewerWorkerSid } }
    );
    return data;
};

/**
 * Unassign a specific task
 */
export const unAssignTask = async ({
    recordId,
    reviewerWorkerSid,
    reviewer,
}: {
    recordId: string;
    reviewerWorkerSid: string;
    reviewer: string;
}) => {
    const { data } = await execute(
        'PUT',
        Api.UNASSIGN_TASK_URL.replace('RECORD_ID', recordId),
        { data: { reviewer, reviewerWorkerSid } }
    );
    return data;
};
