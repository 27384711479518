import { SmallCloseIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Input, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import CircleCheckSvg from '../../../assets/CircleCheckSvg';
import EditableSvg from '../../../assets/EditableSvg';
import { STATUS } from '../../../config/config';
import { account } from '../../../entities/session';

const DetailEditableText: React.FC<{
    recordId: string;
    reviewer: string;
    value?: string;
    fieldKey?: string;
    status: string;
    validator?: any;
    onClick?: Function;
    onChange?: Function;
    onError?: Function;
}> = ({
    recordId,
    fieldKey,
    reviewer = '',
    value,
    status,
    validator = {},
    onClick = () => {},
    onChange = () => {},
    onError = () => {},
}) => {
    const [toggleEditable, setToggleEditable] = useState(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [originalValue, setOriginalValue] = useState(value);

    const accountInfo: any = account.use();

    return (
        <Flex direction={'row'}>
            <Box paddingRight={'4px'}>
                {toggleEditable ? (
                    <Input
                        paddingRight={0}
                        placeholder={value}
                        value={inputValue}
                        disabled={reviewer === accountInfo.name ? false : true}
                        onChange={event => {
                            let val = event.target.value;
                            if (validator && val > validator.maxLength) {
                                val = val.substring(0, validator.maxLength);
                            }

                            setInputValue(val);
                        }}
                    />
                ) : (
                    <Text variant="fieldValue">{inputValue || value || '-'}</Text>
                )}
            </Box>

            <Box w={'23px'} h={'23px'}>
                <Flex direction={'row'} gap={1}>
                    {reviewer === accountInfo.name && status === STATUS.ASSIGNED && (
                        <IconButton
                            aria-label="editable"
                            variant={'transparent'}
                            minH={'unset'}
                            minW={'unset'}
                            h={'24px'}
                            w={'24px'}
                            mt={toggleEditable ? '10px' : '0'}
                            icon={
                                toggleEditable ? (
                                    <CircleCheckSvg />
                                ) : (
                                    <EditableSvg strokecolor={'#000'} />
                                )
                            }
                            onClick={() => {
                                if (toggleEditable) {
                                    const inputLength = inputValue.length;
                                    let error = 0;
                                    let errorCode: any = [];
                                    if (validator && inputLength < validator.minLength) {
                                        error++;
                                        errorCode.push('MIN_LENGTH');
                                    }
                                    if (validator && inputLength > validator.maxLength) {
                                        error++;
                                        errorCode.push('MAX_LENGTH');
                                    }
                                    if (validator && !/^[0-9]+$/.test(inputValue)) {
                                        error++;
                                        errorCode.push('NUMBERS_ONLY');
                                    }

                                    if (error === 0) {
                                        setToggleEditable(false);
                                        setOriginalValue(inputValue);
                                        onChange(recordId, fieldKey, inputValue);
                                    } else {
                                        onError(errorCode);
                                    }
                                } else {
                                    setToggleEditable(true);
                                    onClick();
                                }
                            }}
                        />
                    )}
                    {toggleEditable && (
                        <IconButton
                            aria-label="cancel"
                            variant={'transparent'}
                            minH={'unset'}
                            h={'24px'}
                            w={'24px'}
                            minW={'unset'}
                            mt={toggleEditable ? '10px' : '0'}
                            icon={
                                <SmallCloseIcon
                                    border={'1px solid black'}
                                    borderRadius={'50%'}
                                    w={'20px'}
                                    h={'20px'}
                                />
                            }
                            onClick={() => {
                                setInputValue(originalValue || '');
                                setToggleEditable(false);
                            }}
                        />
                    )}
                </Flex>
            </Box>
        </Flex>
    );
};

export default DetailEditableText;
