import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, IconButton, Link } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import React, { FC, useState } from 'react';
import DataTable from '../components/Table/DataTable';
import StatusChip from '../components/Table/StatusChip';
import TableFilters from '../components/Table/Filters';
import { convertISODateToDateTime } from '../lib/helpers';
import SearchPageBannerSvg from '../assets/SearchPageBannerSvg';
import { exportSearchResultsToCsv, fetchEnrollmentRecords } from '../services/apiService';
import { useQuery } from '@tanstack/react-query';

export interface ISearchTableRow {
    capacity: string;
    color: string;
    dateTime: string;
    duration: string;
    lastStep: string;
    make: string;
    method: string;
    model: string;
    partner: string;
    program: string;
    recordId: string;
    reviewStatus: string;
    reviewer: string;
    submissionStatus: string;
    // inserted thru UI pre processing
    primaryMdn: string;
    secondaryMdn: string;
    sessionCount: number;
    subRows?: ISearchTableRow[];
}

const columnHelper = createColumnHelper<ISearchTableRow>();

const SearchPage: FC = () => {
    // Shared filter states
    const todayDate = new Date();
    const defaultDates = {
        startDate: new Date(todayDate.setHours(0, 0, 0, 0)),
        endDate: new Date(todayDate.setHours(23, 59, 59, 999)),
    };
    const [startDate, setStartDate] = useState(defaultDates.startDate);
    const [endDate, setEndDate] = useState(defaultDates.endDate);

    const mdnTypes = ['Phone to Enroll No.', 'Subscriber No.'];
    const [selectedMdnType, setSelectedMdnType] = useState<string>(mdnTypes[1]);

    const defaultFilterForm = {
        startTime: new Date(todayDate.setHours(0, 0, 0, 0)).toISOString(),
        endTime: new Date(todayDate.setHours(23, 59, 59, 999)).toISOString(),
        mdnType: selectedMdnType === mdnTypes[0] ? 'secondary' : 'primary',
    };

    const [filterForm, setFilterForm] = useState(defaultFilterForm);
    const [filterFormData, setFilterFormData] = useState(defaultFilterForm);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    // Pagination
    const [pageToken, setPageToken] = useState('');
    const [pageNum, setPageNum] = useState(1);
    const [rowLimit, setRowLimit] = useState(10);

    const columns = [
        columnHelper.accessor('sessionCount', {
            cell: ({ row, getValue }) =>
                getValue() > 1 ? (
                    <Center>
                        <IconButton
                            aria-label="add session"
                            bgColor="black"
                            icon={
                                row.getIsExpanded() ? (
                                    <MinusIcon color="white" w="10px" h="10px" />
                                ) : (
                                    <AddIcon color="white" w="10px" h="10px" />
                                )
                            }
                            borderRadius="50%"
                            minH="35px"
                            minW="35px"
                            h="16px"
                            w="16px"
                            onClick={() => row.toggleExpanded()}
                        />
                        <Box pl="8px" fontSize="16px">
                            {getValue()}
                        </Box>
                    </Center>
                ) : (
                    <></>
                ),
            header: 'Session',
        }),
        columnHelper.accessor('secondaryMdn', {
            cell: ({ row, getValue }) =>
                row.original.sessionCount > 0 ? (
                    <Flex justifyContent="center">
                        <Link
                            href={`/enrollment/${row.original.primaryMdn.replaceAll(
                                '+',
                                ''
                            )}/${String(getValue()).replaceAll('+', '')}`}
                        >
                            {getValue()}
                        </Link>
                    </Flex>
                ) : (
                    <></>
                ),
            header: 'Phone to Enroll No.',
        }),
        columnHelper.accessor('primaryMdn', {
            cell: ({ getValue }) => <Box>{getValue()}</Box>,
            header: 'Subscriber No.',
        }),
        columnHelper.accessor('dateTime', {
            cell: info =>
                info.getValue() !== '' ? (
                    <Box w="90px">{convertISODateToDateTime(info.getValue())}</Box>
                ) : (
                    <></>
                ),
            header: 'Date & Time',
        }),
        columnHelper.accessor(row => `${row.submissionStatus} ${row.lastStep}`, {
            id: 'submissionStatus',
            cell: ({ row }) =>
                row.original.submissionStatus !== 'complete' ? (
                    <StatusChip status="INCOMPLETE" customText={row.original.lastStep} />
                ) : (
                    <StatusChip status="COMPLETE" />
                ),
            header: 'Submission Status',
        }),
        columnHelper.accessor(
            row => `${row.make} ${row.model}, ${row.capacity}, ${row.color}`,
            {
                cell: ({ row }) => (
                    <>
                        {`${row.original.make || ''} ${row.original.model || ''}`} <br />
                        {`${row.original.capacity || ''} ${row.original.color || ''}`}
                    </>
                ),
                header: 'Model',
            }
        ),
        columnHelper.accessor(row => `${row.partner} ${row.program}`, {
            id: 'partnerPlan',
            cell: ({ row }) => (
                <Box textTransform="capitalize" maxW="200px">
                    {row.original.partner}
                    <br />
                    {row.original.program}
                </Box>
            ),
            header: 'Partner & Plan',
        }),
        columnHelper.accessor(row => `${row.method} ${row.duration}`, {
            id: 'methodDuration',
            cell: ({ row }) => (
                <>
                    {`${row.original.method}`} <br /> {`${row.original.duration}`}
                </>
            ),
            header: 'Method & Duration',
        }),
        columnHelper.accessor('reviewStatus', {
            id: 'reviewStatus',
            cell: info => (
                <StatusChip
                    status={
                        info.getValue()
                            ? info.getValue().toUpperCase().replaceAll(' ', '_')
                            : 'UNDEFINED'
                    }
                />
            ),
            header: 'Review Status',
        }),
        columnHelper.accessor('reviewer', {
            id: 'reviewer',
            cell: info => info.getValue(),
            header: 'Reviewer',
        }),
    ];

    const downloadBlob = (content, fileName, contentType) => {
        const blob = new Blob([content], { type: contentType });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.click();
    };

    const handleExport = async () => {
        const csvData = await exportSearchResultsToCsv(filterFormData);
        downloadBlob(csvData, 'search-export.csv', 'text/csv;charset=utf-8;')
    }

    const useTableData = () => {
        return useQuery(['session', rowLimit, pageToken, filterFormData], () =>
            fetchEnrollmentRecords(rowLimit, pageToken, filterFormData)
        );
    };

    const {
        data: tableData,
        error: errorTableData,
        isFetching: isFetchingTableData,
        refetch: refetchTableData,
    } = useTableData();

    return (
        <Box
            id="search-page"
            backgroundColor="asuWhite.200"
            h="fit-content"
            w="100%"
            boxSizing="border-box"
            pt="60px"
        >
            <Box position="absolute" width="100%" zIndex={1}>
                <Flex width="inherit">
                    <SearchPageBannerSvg style={{ margin: 'auto' }} />
                </Flex>
            </Box>
            <Flex
                position="relative"
                direction="column"
                boxSizing="border-box"
                backgroundColor="asuWhite.200"
                p="80px 12px 25px"
                w="100%"
                h="fit-content"
                border="none"
            >
                <TableFilters
                    filterForm={filterForm}
                    setFilterForm={setFilterForm}
                    setIsFormSubmitted={setIsFormSubmitted}
                    setFilterFormData={setFilterFormData}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setPageToken={setPageToken}
                    setPageNum={setPageNum}
                    defaultFilterForm={defaultFilterForm}
                    defaultDates={defaultDates}
                    mdnTypes={mdnTypes}
                    selectedMdnType={selectedMdnType}
                    setSelectedMdnType={setSelectedMdnType}
                    handleExport={handleExport}
                    refetchTableData={refetchTableData}
                />
                <Box w="100%" h="fit-content" backgroundColor="asuWhite.200" m="95px auto 0">
                    <Box w="100%" h="fit-content" backgroundColor="white" >
                        <DataTable
                            tableData={tableData}
                            errorTableData={errorTableData}
                            isFetchingTableData={isFetchingTableData}
                            columns={columns}
                            pageNum={pageNum}
                            setPageNum={setPageNum}
                            setPageToken={setPageToken}
                            rowLimit={rowLimit}
                            setRowLimit={setRowLimit}
                        />
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
};

export default SearchPage;
