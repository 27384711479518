import React, { useState, useRef, Dispatch, SetStateAction } from 'react';
import {
    Box,
    Button,
    Center,
    Checkbox,
    Flex,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spinner,
    Text,
} from '@chakra-ui/react';
import CalendarSvg from '../../assets/CalendarSvg';
import CheckListSvg from '../../assets/CheckListSvg';
import DropdownSvg from '../../assets/DropdownSvg';
import TagSvg from '../../assets/TagSvg';
import UserProfileSvg from '../../assets/UseProfileSvg';
import DatePicker from '../DatePicker';
import StatusChip from './StatusChip';
import { convertISODateToDateTime, isFeatureEnabled } from '../../lib/helpers';
import { Search2Icon } from '@chakra-ui/icons';
import { prevPageTokenStore } from '../../entities/enrollments';
import FilterPhoneSvg from '../../assets/FilterPhoneSvg';
import { fetchEnrollmentFilters } from '../../services/apiService';
import { useQuery } from '@tanstack/react-query';
import { activeRegion } from '../../entities/session';
import { AppFeatures } from '../../config/config';
import FmipRefreshSvg from '../../assets/FmipRefreshSvg';

interface TableFilterProps {
    filterForm: any;
    setFilterForm: any;
    setIsFormSubmitted: Dispatch<SetStateAction<boolean>>;
    setFilterFormData: any;
    startDate: any;
    setStartDate: any;
    endDate: any;
    setEndDate: any;
    setPageToken: any;
    setPageNum: any;
    defaultFilterForm: any;
    defaultDates: any;
    mdnTypes: string[];
    selectedMdnType: any;
    setSelectedMdnType: any;
    handleExport: any;
    refetchTableData: any;
}

const TableFilters: React.FC<TableFilterProps> = ({
    filterForm,
    setFilterForm,
    setIsFormSubmitted,
    setFilterFormData,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setPageToken,
    setPageNum,
    defaultFilterForm,
    defaultDates,
    mdnTypes,
    selectedMdnType,
    setSelectedMdnType,
    handleExport,
    refetchTableData
}) => {
    const searchInputRef = useRef<HTMLInputElement>(null);
    const region: any = activeRegion.use();

    const updateSelectedList = (selectedList, setSelectedList, option) => {
        let updatedList: string[] = [...selectedList];
        const idx = updatedList.indexOf(option);

        if (idx === -1) {
            updatedList.push(option);
        } else {
            updatedList.splice(idx, 1);
        }

        setSelectedList([...updatedList]);
        return [...updatedList];
    };

    const onStartDateChange = (date: Date) => {
        setStartDate(date);
        setFilterForm({
            ...filterForm,
            startTime: date.toISOString(),
        });
    };

    const onEndDateChange = (date: Date) => {
        setEndDate(date);

        const updatedEndTime = new Date(date.setHours(23, 59, 59, 999));
        setFilterForm({
            ...filterForm,
            endTime: updatedEndTime.toISOString(),
        });
    };

    const hasDateRangeFilter =
        Object.keys(filterForm).includes('startTime') ||
        Object.keys(filterForm).includes('endTime');

    // Selected filter states
    const [selectedPartners, setSelectedPartners] = useState<string[]>([]);
    const [selectedSubStatus, setSelectedSubStatus] = useState<string[]>([]);
    const [selectedRevStatus, setSelectedRevStatus] = useState<string[]>([]);
    const [selectedReviewers, setSelectedReviewers] = useState<string[]>([]);

    const useTableFilterData = (filterType: string) => {
        return useQuery(['filters', filterType, region], () =>
            fetchEnrollmentFilters(filterType, region)
        );
    };

    const {
        data: partnerFilters,
        isFetching: partnersFetching,
        error: partnersError,
    } = useTableFilterData('partner');

    const {
        data: subStatusFilters,
        isFetching: subStatusFetching,
        error: subStatusError,
    } = useTableFilterData('submissionStatus');

    const {
        data: reviewerFilters,
        isFetching: reviewersFetching,
        error: reviewersError,
    } = useTableFilterData('reviewerName');

    const {
        data: revStatusFilters,
        isFetching: revStatusFetching,
        error: revStatusError,
    } = useTableFilterData('reviewStatus');

    return (
        <Flex direction="column" width="fit-content" m="auto" zIndex={1}>
            <InputGroup w="100%" h="48px" margin="auto">
                <Input
                    ref={searchInputRef}
                    type="number"
                    placeholder="Search mobile number"
                    backgroundColor="white"
                    borderColor="asuGray.50"
                    variant="outline"
                    size="md"
                    m="0 4px"
                />
                <InputRightElement>
                    <Search2Icon />
                </InputRightElement>
            </InputGroup>
            <Center>
                <Menu closeOnSelect={false}>
                    <MenuButton
                        w="119px"
                        h="37px"
                        borderRadius="4px"
                        border="1px solid"
                        borderColor={selectedMdnType === '' ? '#979797' : 'asuPurple.500'}
                        backgroundColor={
                            selectedMdnType === '' ? '#f2f2f2' : 'asuBlue.300'
                        }
                        color="black"
                        fontSize="16px"
                        fontWeight="700"
                        p="8px 12px"
                        m="4px"
                        alignContent="center"
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <FilterPhoneSvg />
                            <Text lineHeight="21px" alignSelf="self-end">
                                {`${selectedMdnType !== '' ? ` ${1}  ` : ''}`}
                            </Text>
                            <Text lineHeight="21px" alignSelf="self-end">
                                Phone
                            </Text>
                            <DropdownSvg />
                        </Box>
                    </MenuButton>
                    <MenuList maxH="210px" overflowY="auto" p="0">
                        <>
                            {mdnTypes.map((mdnType, idx) => (
                                <MenuItem minH="48px" key={`status-option-${idx}`}>
                                    <Checkbox
                                        variant="filterOption"
                                        isChecked={selectedMdnType === mdnType}
                                        onChange={e => {
                                            e.preventDefault();
                                            setSelectedMdnType(mdnType);
                                        }}
                                    >
                                        {mdnType}
                                    </Checkbox>
                                </MenuItem>
                            ))}
                        </>
                    </MenuList>
                </Menu>
                <Menu>
                    <MenuButton
                        w={hasDateRangeFilter ? '252px' : '154px'}
                        h="37px"
                        border="1px solid"
                        borderRadius="4px"
                        borderColor={hasDateRangeFilter ? 'asuPurple.500' : '#979797'}
                        backgroundColor={hasDateRangeFilter ? 'asuBlue.300' : '#f2f2f2'}
                        color="black"
                        fontSize="16px"
                        fontWeight="700"
                        alignItems="center"
                        alignContent="center"
                        p="8px 12px"
                        m="4px"
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            {hasDateRangeFilter ? (
                                <>
                                    <CalendarSvg />
                                    {convertISODateToDateTime(
                                        startDate.toISOString(),
                                        true
                                    )}
                                    {' - '}
                                    <CalendarSvg />
                                    {convertISODateToDateTime(
                                        endDate.toISOString(),
                                        true
                                    )}
                                </>
                            ) : (
                                <>
                                    <CalendarSvg />
                                    {'Date Range'}
                                </>
                            )}
                            <DropdownSvg />
                        </Box>
                    </MenuButton>
                    <MenuList
                        h="41px"
                        minW="214px"
                        p="12px"
                        filter="drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.08))"
                    >
                        <DatePicker
                            startDate={startDate}
                            onStartDateChange={onStartDateChange}
                            endDate={endDate}
                            onEndDateChange={onEndDateChange}
                        />
                    </MenuList>
                </Menu>
                <Menu closeOnSelect={false}>
                    <MenuButton
                        w={selectedSubStatus.length === 0 ? '202px' : '212px'}
                        h="37px"
                        borderRadius="4px"
                        border="1px solid"
                        borderColor={
                            selectedSubStatus.length === 0 ? '#979797' : 'asuPurple.500'
                        }
                        backgroundColor={
                            selectedSubStatus.length === 0 ? '#f2f2f2' : 'asuBlue.300'
                        }
                        color="black"
                        fontSize="16px"
                        fontWeight="700"
                        p="8px 12px"
                        m="4px"
                        alignContent="center"
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <CheckListSvg />
                            <Text lineHeight="21px" alignSelf="self-end">
                                {`${selectedSubStatus.length > 0
                                    ? ` ${selectedSubStatus.length}  `
                                    : ''
                                    }`}
                            </Text>
                            <Text lineHeight="21px" alignSelf="self-end">
                                Submission Status
                            </Text>
                            <DropdownSvg />
                        </Box>
                    </MenuButton>
                    <MenuList maxH="210px" overflowY="auto" p="0">
                        <>
                            {subStatusFetching ? (
                                <Flex direction="column" alignItems="center" h="150px">
                                    <Spinner variant={'pageLoader'} m="auto" />
                                </Flex>
                            ) : (
                                <>
                                    {subStatusError ? (
                                        <Flex
                                            direction="column"
                                            alignItems="center"
                                            h="150px"
                                        >
                                            <Text m="auto">Error fetching filters</Text>
                                        </Flex>
                                    ) : (
                                        <>
                                            {subStatusFilters.map(
                                                (submissionStatus, idx) => (
                                                    <MenuItem
                                                        minH="48px"
                                                        key={`status-option-${idx}`}
                                                    >
                                                        <Checkbox
                                                            isChecked={selectedSubStatus.includes(
                                                                submissionStatus
                                                            )}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                const updatedStatus =
                                                                    updateSelectedList(
                                                                        selectedSubStatus,
                                                                        setSelectedSubStatus,
                                                                        submissionStatus
                                                                    );

                                                                if (
                                                                    updatedStatus.length ===
                                                                    0
                                                                ) {
                                                                    delete filterForm.submissionStatus;
                                                                } else {
                                                                    setFilterForm({
                                                                        ...filterForm,
                                                                        submissionStatus:
                                                                            updatedStatus,
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <StatusChip
                                                                status={submissionStatus}
                                                            />
                                                        </Checkbox>
                                                    </MenuItem>
                                                )
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    </MenuList>
                </Menu>
                <Menu closeOnSelect={false}>
                    <MenuButton
                        w={selectedPartners.length === 0 ? '128px' : '137px'}
                        h="37px"
                        borderRadius="4px"
                        border="1px solid"
                        borderColor={
                            selectedPartners.length === 0 ? '#979797' : 'asuPurple.500'
                        }
                        backgroundColor={
                            selectedPartners.length === 0 ? '#f2f2f2' : 'asuBlue.300'
                        }
                        color="black"
                        fontSize="16px"
                        fontWeight="700"
                        p="8px 12px"
                        m="4px"
                        alignContent="center"
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <TagSvg />
                            <Text lineHeight="21px" alignSelf="self-end">
                                {`${selectedPartners.length > 0
                                    ? ` ${selectedPartners.length}  `
                                    : ''
                                    }`}
                            </Text>
                            <Text lineHeight="21px" alignSelf="self-end">
                                Partner
                            </Text>
                            <DropdownSvg />
                        </Box>
                    </MenuButton>
                    <MenuList maxH="210px" overflowY="auto" p="0">
                        <>
                            {partnersFetching ? (
                                <Flex direction="column" alignItems="center" h="150px">
                                    <Spinner variant={'pageLoader'} m="auto" />
                                </Flex>
                            ) : (
                                <>
                                    {partnersError ? (
                                        <Flex
                                            direction="column"
                                            alignItems="center"
                                            h="150px"
                                        >
                                            <Text m="auto">Error fetching filters</Text>
                                        </Flex>
                                    ) : (
                                        <>
                                            {partnerFilters.map((partner, idx) => (
                                                <MenuItem
                                                    minH="48px"
                                                    key={`partner-option-${idx}`}
                                                    textTransform="capitalize"
                                                >
                                                    <Checkbox
                                                        isChecked={selectedPartners.includes(
                                                            partner.toLowerCase()
                                                        )}
                                                        onChange={e => {
                                                            e.preventDefault();
                                                            const updatedPartners =
                                                                updateSelectedList(
                                                                    selectedPartners,
                                                                    setSelectedPartners,
                                                                    partner.toLowerCase()
                                                                );
                                                            if (
                                                                updatedPartners.length ===
                                                                0
                                                            ) {
                                                                delete filterForm.partner;
                                                            } else {
                                                                setFilterForm({
                                                                    ...filterForm,
                                                                    partner:
                                                                        updatedPartners,
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        {partner}
                                                    </Checkbox>
                                                </MenuItem>
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    </MenuList>
                </Menu>
                <Menu closeOnSelect={false}>
                    <MenuButton
                        w={selectedRevStatus.length === 0 ? '172px' : '182px'}
                        h="37px"
                        borderRadius="4px"
                        border="1px solid"
                        borderColor={
                            selectedRevStatus.length === 0 ? '#979797' : 'asuPurple.500'
                        }
                        backgroundColor={
                            selectedRevStatus.length === 0 ? '#f2f2f2' : 'asuBlue.300'
                        }
                        color="black"
                        fontSize="16px"
                        fontWeight="700"
                        p="8px 12px"
                        m="4px"
                        alignContent="center"
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <CheckListSvg />
                            <Text lineHeight="21px" alignSelf="self-end">
                                {`${selectedRevStatus.length > 0
                                    ? ` ${selectedRevStatus.length}  `
                                    : ''
                                    }`}
                            </Text>
                            <Text lineHeight="21px" alignSelf="self-end">
                                Review Status
                            </Text>
                            <DropdownSvg />
                        </Box>
                    </MenuButton>
                    <MenuList maxH="210px" overflowY="auto" p="0">
                        <>
                            {revStatusFetching ? (
                                <Flex direction="column" alignItems="center" h="150px">
                                    <Spinner variant={'pageLoader'} m="auto" />
                                </Flex>
                            ) : (
                                <>
                                    {revStatusError ? (
                                        <Flex
                                            direction="column"
                                            alignItems="center"
                                            h="150px"
                                        >
                                            <Text m="auto">Error fetching filters</Text>
                                        </Flex>
                                    ) : (
                                        <>
                                            {revStatusFilters.map((reviewStatus, idx) => (
                                                <MenuItem
                                                    minH="48px"
                                                    key={`revstatus-option-${idx}`}
                                                >
                                                    <Checkbox
                                                        isChecked={selectedRevStatus.includes(
                                                            reviewStatus
                                                        )}
                                                        onChange={e => {
                                                            e.preventDefault();
                                                            const updatedStatus =
                                                                updateSelectedList(
                                                                    selectedRevStatus,
                                                                    setSelectedRevStatus,
                                                                    reviewStatus
                                                                );
                                                            if (
                                                                updatedStatus.length === 0
                                                            ) {
                                                                delete filterForm.reviewStatus;
                                                            } else {
                                                                setFilterForm({
                                                                    ...filterForm,
                                                                    reviewStatus:
                                                                        updatedStatus,
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <StatusChip
                                                            status={reviewStatus.replaceAll(
                                                                ' ',
                                                                '_'
                                                            )}
                                                        />
                                                    </Checkbox>
                                                </MenuItem>
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    </MenuList>
                </Menu>
                <Menu closeOnSelect={false}>
                    <MenuButton
                        w={selectedReviewers.length === 0 ? '185px' : '149px'}
                        h="37px"
                        border="1px solid"
                        borderRadius="4px"
                        borderColor={
                            selectedReviewers.length === 0 ? '#979797' : 'asuPurple.500'
                        }
                        backgroundColor={
                            selectedReviewers.length === 0 ? '#f2f2f2' : 'asuBlue.300'
                        }
                        color="black"
                        fontSize="16px"
                        fontWeight="700"
                        alignItems="center"
                        alignContent="center"
                        p="8px 12px"
                        m="4px"
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <UserProfileSvg />
                            <Text lineHeight="21px" alignSelf="self-end">
                                {`${selectedReviewers.length > 0
                                    ? ` ${selectedReviewers.length}  `
                                    : ''
                                    }`}
                            </Text>
                            <Text lineHeight="21px" alignSelf="self-end">
                                {selectedReviewers.length > 0
                                    ? 'Reviewer'
                                    : 'Reviewer Name'}
                            </Text>
                            <DropdownSvg />
                        </Box>
                    </MenuButton>
                    <MenuList maxH="210px" overflowY="auto" p="0">
                        <>
                            {reviewersFetching ? (
                                <Flex direction="column" alignItems="center" h="150px">
                                    <Spinner variant={'pageLoader'} m="auto" />
                                </Flex>
                            ) : (
                                <>
                                    {reviewersError ? (
                                        <Flex
                                            direction="column"
                                            alignItems="center"
                                            h="150px"
                                        >
                                            <Text m="auto">Error fetching filters</Text>
                                        </Flex>
                                    ) : (
                                        <>
                                            {reviewerFilters.map((reviewer, idx) => (
                                                <MenuItem
                                                    minH="48px"
                                                    key={`reviewer-option-${idx}`}
                                                >
                                                    <Checkbox
                                                        isChecked={selectedReviewers.includes(
                                                            reviewer
                                                        )}
                                                        onChange={e => {
                                                            e.preventDefault();
                                                            const updatedReviewers =
                                                                updateSelectedList(
                                                                    selectedReviewers,
                                                                    setSelectedReviewers,
                                                                    reviewer
                                                                );

                                                            if (
                                                                updatedReviewers.length ===
                                                                0
                                                            ) {
                                                                delete filterForm.reviewer;
                                                            } else {
                                                                setFilterForm({
                                                                    ...filterForm,
                                                                    reviewer:
                                                                        updatedReviewers,
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        {reviewer}
                                                    </Checkbox>
                                                </MenuItem>
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    </MenuList>
                </Menu>
                <Button
                    variant="outline"
                    w="135px"
                    minH="36px"
                    borderColor="asuPurple.500"
                    background="asuPurple.500"
                    color="white"
                    m="4px"
                    onClick={() => {
                        prevPageTokenStore.set({});
                        setPageToken('');
                        setPageNum(1);
                        if (
                            searchInputRef.current &&
                            searchInputRef.current.value !== '' &&
                            selectedMdnType !== ''
                        ) {
                            let updatedForm = {
                                ...filterForm,
                                mdn: searchInputRef.current.value.toString(),
                                mdnType:
                                    selectedMdnType === mdnTypes[0]
                                        ? 'secondary'
                                        : 'primary',
                            };
                            setFilterFormData({ ...updatedForm });
                        } else {
                            setFilterFormData({ ...filterForm });
                        }
                        setIsFormSubmitted(true);
                    }}
                >
                    Apply filters
                </Button>
                <Button
                    variant="outline"
                    w="88px"
                    minH="36px"
                    borderColor="asuPurple.500"
                    color="asuPurple.500"
                    background="white"
                    m="4px"
                    onClick={() => {
                        setStartDate(defaultDates.startDate);
                        setEndDate(defaultDates.endDate);
                        setSelectedSubStatus([]);
                        setSelectedPartners([]);
                        setSelectedRevStatus([]);
                        setSelectedReviewers([]);
                        if (searchInputRef.current) {
                            searchInputRef.current.value = '';
                        }
                        setSelectedMdnType(mdnTypes[1]);
                        setIsFormSubmitted(false);
                        setFilterForm(defaultFilterForm);
                        setFilterFormData(defaultFilterForm);
                        prevPageTokenStore.set({});
                        setPageToken('');
                        setPageNum(1);
                    }}
                >
                    Clear
                </Button>
                {isFeatureEnabled(AppFeatures.EXPORT_SEARCH) && (
                    <Button
                        variant="outline"
                        w="100px"
                        minH="36px"
                        borderColor="asuPurple.500"
                        color="asuPurple.500"
                        background="white"
                        m="4px"
                        onClick={handleExport}
                    >
                        Export
                    </Button>
                )}
                <IconButton
                    aria-label="refetch sessions"
                    icon={<FmipRefreshSvg />}
                    variant="transparent"
                    w="50px"
                    h="50px"
                    minH="unset"
                    minW="unset"
                    m="auto"
                    onClick={refetchTableData}
                />
            </Center>
        </Flex>
    );
};
export default TableFilters;
